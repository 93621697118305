
import PropTypes from 'prop-types'
import GenerateIcon from './GenerateIcon'

const TwitchIcon = ({ size }) => {
    const body = () => {
        return (
            <>
                <rect width="16" height="16" rx="3" fill="#6441A5"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3335 4.07825H5.27073C5.14132 4.07825 5.01584 4.14492 4.94525 4.25472L4.34132 5.15668C4.29819 5.21942 4.27466 5.29785 4.27466 5.37236V10.3528C4.27466 10.5684 4.45113 10.7449 4.66682 10.7449H5.45113C5.66682 10.7449 5.84329 10.9214 5.84329 11.1371V11.5292C5.84329 11.7449 6.01976 11.9214 6.23544 11.9214H6.80799C6.93741 11.9214 7.06289 11.8547 7.13348 11.7449L7.68642 10.9175C7.76093 10.8077 7.8825 10.741 8.01191 10.741H9.60015C9.70603 10.741 9.80407 10.6979 9.87858 10.6273L11.608 8.89786C11.6825 8.82335 11.7217 8.7253 11.7217 8.61942V4.4704C11.7256 4.25472 11.5492 4.07825 11.3335 4.07825ZM7.52564 8.33706C7.52564 8.46255 7.42368 8.5606 7.30211 8.5606H6.85505C6.72956 8.5606 6.63152 8.45863 6.63152 8.33706V6.09785C6.63152 5.97236 6.73348 5.87432 6.85505 5.87432H7.30211C7.4276 5.87432 7.52564 5.97628 7.52564 6.09785V8.33706ZM9.76485 8.33706C9.76485 8.46255 9.66289 8.5606 9.54132 8.5606H9.09426C8.96877 8.5606 8.87074 8.45863 8.87074 8.33706V6.09785C8.87074 5.97236 8.97269 5.87432 9.09426 5.87432H9.54132C9.66681 5.87432 9.76485 5.97628 9.76485 6.09785V8.33706Z" fill="white"/>
            </>
        )
    }

    return (
        <GenerateIcon
            viewBox={'0 0 16 16'}
            size={size}
            animate={false}
            cmp={body()}
        />
    )
}

TwitchIcon.propTypes = {
    size: PropTypes.number,
}

TwitchIcon.defaultProps = {
    size: 25,
}

export default TwitchIcon
