
export const initialState = {
    list: [],
    hits: [],
    limit: 0,
    hasLoaded: false,
}

/**
 * Actions
 */

export const SET_LINKS_DATA = 'setLinksData@links'

export const setLinksData = ({ hits, list, limit }) => ({
    type: SET_LINKS_DATA,
    payload: { hits, list, limit },
})

/**
 * Handlers
 */

export const actionHandlers = {
    '@reset': () => ({ ...initialState }),
    [SET_LINKS_DATA]: (state, { payload }) => ({
        ...state,
        list: payload.list,
        hits: payload.hits,
        limit: payload.limit,
        hasLoaded: true,
    }),
}

export const reducer = (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return handler ? handler(state, action) : state
}

export default reducer
