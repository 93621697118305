
import PropTypes from 'prop-types'

import Text from 'components/Text'
import { convertNumber } from 'lib/numbers'

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
    },
}

const MetricCard = ({
    label,
    value,
}) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Text
                size={'p'}
                children={label}
            />
            <Text
                size={'h3'}
                weight={'semiBold'}
                children={value}
            />
        </div>
    )
}

const AnalyticsMetrics = ({ clicks }) => {
    const valuePerClick = (val) => (0.00366 * val).toFixed(2)

    return (
        <div style={styles.wrapper}>
            <MetricCard
                label={'Clicks'}
                value={convertNumber(clicks)}
            />
            <MetricCard
                label={'Clicks Value'}
                value={'$' + valuePerClick(clicks)}
            />
        </div>
    )
}

AnalyticsMetrics.propTypes = {
    clicks: PropTypes.number,
}

AnalyticsMetrics.defaultProps = {
    clicks: 0,
}

MetricCard.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
}

export default AnalyticsMetrics
