
import PropTypes from 'prop-types'
import GenerateIcon from './GenerateIcon'

const YoutubeIcon = ({ size }) => {
    const body = () => {
        return (
            <>
                <rect width="16" height="16" rx="3" fill="#C4302B"/>
                <path d="M10.1569 4.54919H5.84314C4.54902 4.54919 3.68628 5.41194 3.68628 6.70606V9.29429C3.68628 10.5884 4.54902 11.4512 5.84314 11.4512H10.1569C11.451 11.4512 12.3137 10.5884 12.3137 9.29429V6.70606C12.3137 5.41194 11.451 4.54919 10.1569 4.54919ZM8.8153 8.44449L7.7498 9.08292C7.31843 9.34175 6.9647 9.14332 6.9647 8.63861V7.35743C6.9647 6.85273 7.31843 6.6543 7.7498 6.91312L8.8153 7.55154C9.22511 7.80174 9.22511 8.19861 8.8153 8.44449Z" fill="white"/>

            </>
        )
    }

    return (
        <GenerateIcon
            viewBox={'0 0 16 16'}
            size={size}
            animate={false}
            cmp={body()}
        />
    )
}

YoutubeIcon.propTypes = {
    size: PropTypes.number,
}

YoutubeIcon.defaultProps = {
    size: 25,
}

export default YoutubeIcon
