
import Spinner from 'components/Spinner'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    innerWrapper: {
        borderRadius: 10,
        margin: '30px 15px',
        padding: '30px 15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: 450,
    },
}

const LoadingUI = () => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.innerWrapper}>
                <Spinner size={60}/>
            </div>
        </div>
    )
}

export default LoadingUI
