/**
 * Listen to the login/logout status to activate or deactivate the
 * snaps subscriptions
 */

import { SET_IDENTITY } from 'features/feature-auth'
import { findOwner } from './links.service'

export default [
    {
        type: SET_IDENTITY,
        handler: ({ payload }) => async (dispatch, getState) => {
            payload && dispatch(findOwner())
        },
    },
]
