import { SET_IDENTITY } from 'features/feature-auth'

import { fetchTutorials, disableTutorial } from './tutorials.service'

export default [
    {
        type: SET_IDENTITY,
        handler: ({ payload }) => (dispatch) => {
            payload && dispatch(fetchTutorials())
        },
    },
    {
        type: '@tutorials::disable',
        handler: ({ payload }) => (dispatch) => {
            dispatch(disableTutorial(payload.name))
        },
    },
]
