import dayjs from 'dayjs'
import { isAndroid } from 'react-device-detect'

import { runQuery } from 'features/feature-network'
import queries from './graphql'
import { setLinksData } from './links.reducer'
import { localStorage } from 'features/feature-storage'

const LOCAL_STORAGE_KEY = 'links::add'

/*
    local storage structure

    {
        [ownerId]: {
            [url]: {
                id,
                vendor,
                title,
            },
        },
    }
*/

export const getMonthLimit = (list) => {
    let limit = 3

    for (let i = 0; i < list.length; i++) {
        const item = list[i]
        const date = item.ctime
        if (limit === 0) break

        const isSameYear = dayjs().isSame(date, 'year')
        const isSameMonth = dayjs().isSame(date, 'month')
        if (isSameYear && isSameMonth) {
            limit -= 1
        }
    }

    return limit
}

export const findOwner = () => async (dispatch) => {
    const res = await dispatch(runQuery(queries.findOwner))
    const data = res.data.session.links.findOwner

    dispatch(setLinksData({
        list: data.links,
        hits: data.hits,
        limit: getMonthLimit(data.links),
    }))
}

export const findOwnerKey = (key) => async (dispatch) => {
    const res = await dispatch(runQuery(queries.findOwnerKey, { key }))
    const data = res.data.session.links.findOwnerKey
    return data
}

export const editOwnerKey = (key, newKey) => async (dispatch) => {
    await dispatch(runQuery(queries.editOwnerKey, { key, newKey }))
    dispatch(findOwner())
}

// should only be used in share container
export const findKey = (key) => async (dispatch) => {
    const res = await dispatch(runQuery(queries.findKey, { key, isAndroid }))
    return res.data.links.findKeyV2
}

export const add = (url) => async (dispatch, getState) => {
    const { auth, links } = getState()

    // if link info exist it local storage, send data to UI
    const userId = auth.identity.id
    const local = dispatch(localStorage.getItem(LOCAL_STORAGE_KEY, {}))
    const ownerLocal = local[userId] || {}
    const linkLocal = ownerLocal[url]
    if (linkLocal) return linkLocal

    if (!auth.isPro && !links.limit) throw new Error('need pro')

    const res = await dispatch(runQuery(queries.add, { url }))
    const data = res.data.session.links.addV2

    // add new link data to local storage
    dispatch(localStorage.setItem(LOCAL_STORAGE_KEY, {
        ...local,
        [userId]: {
            ...ownerLocal,
            [url]: data,
        },
    }))

    dispatch(findOwner())
    return data
}
