
export const validateInput = (input) => {
    const parsedUrl = new URL(input)
    const host = parsedUrl.host
    if (!host) throw new Error('invalid url')

    const url = parsedUrl.origin + parsedUrl.pathname

    if (host.indexOf('tiktok.com') !== -1) {
        return {
            vendor: 'tiktok',
            url,
        }
    }

    if (host.indexOf('instagram.com') !== -1) {
        return {
            vendor: 'instagram',
            url,
        }
    }
    if (host.indexOf('twitter.com') !== -1) {
        return {
            vendor: 'twitter',
            url,
        }
    }

    if (host.indexOf('twitch.tv') !== -1 || host.indexOf('twitch.com') !== -1) {
        return {
            vendor: 'twitch',
            url,
        }
    }

    if (host.indexOf('snapchat.com') !== -1) {
        return {
            vendor: 'snapchat',
            url,
        }
    }

    if (host.indexOf('youtube.com') !== -1) {
        if (parsedUrl.pathname.indexOf('watch') !== -1 && parsedUrl.search.indexOf('?v=') !== -1) {
            return {
                vendor: 'youtube',
                url: url + parsedUrl.search,
            }
        }

        return {
            vendor: 'youtube',
            url,
        }
    }

    if (host.indexOf('youtu.be') !== -1) {
        return {
            vendor: 'youtube',
            url,
        }
    }

    if (host.indexOf('spotify.com') !== -1) {
        return {
            vendor: 'spotify',
            url,
        }
    }

    if (host.indexOf('facebook.com') !== -1) {
        return {
            vendor: 'facebook',
            url,
        }
    }

    throw new Error('invalid url')
}
