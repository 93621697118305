
import PropTypes from 'prop-types'

import ProAlert from 'components/ProAlert'
import { ReactComponent as ProLimitGraphics } from 'resources/images/pro-limit-graphics.svg'
import { ReactComponent as ProLockedGraphics } from 'resources/images/pro-locked-graphics.svg'

const ProPopup = ({ showPro, close, limit }) => {
    return (
        <ProAlert
            graphics={showPro === 'limit' ? <ProLimitGraphics /> : <ProLockedGraphics />}
            title={showPro === 'limit' ? `You have ${limit} smartlinks available this month` : 'You have reached your smartlink limit'}
            description={showPro === 'limit' ? 'Upgrade to Mysocial Pro to create unlimited smartlinks' : 'Upgrade to Mysocial Pro to create more smartlinks. Or wait until next month to get 3 new free smartlinks.' }
            isOpen={showPro !== null}
            close={close}
            openPro={() => {
                window.open('https://app.mysocial.io/open/pro')
                close()
            }}
        />
    )
}

ProPopup.propTypes = {
    showPro: PropTypes.string,
    close: PropTypes.func,
    limit: PropTypes.number,
}

ProPopup.defaultProps = {
    showPro: null,
    close: () => null,
    limit: null,
}

export default ProPopup
