
export const initialState = {
    identity: null,
    isValid: null,
    isPro: false,
    isAdmin: false,
}

/**
 * Actions
 */

export const SET_IDENTITY = 'setIdentity@auth'
export const SET_IS_VALID = 'setIsValid@auth'

export const setIdentity = (identity) => ({
    type: SET_IDENTITY,
    payload: identity,
})

export const setIsValid = (bool) => ({
    type: SET_IS_VALID,
    payload: bool,
})

/**
 * Handlers
 */

export const actionHandlers = {
    '@reset': () => ({ ...initialState }),
    [SET_IDENTITY]: (state, { payload }) => ({
        ...state,
        identity: payload,
        isPro: payload.grant.includes('pro'),
        isAdmin: payload.grant.includes('admin'),
    }),
    [SET_IS_VALID]: (state, { payload }) => ({
        ...state,
        isValid: payload,
    }),
}

export const reducer = (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return handler ? handler(state, action) : state
}

export default reducer
