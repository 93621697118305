
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useState } from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import { capitalizeString } from 'lib/strings'
import Icon from 'components/Iconly'

const styles = {
    wrapper: {
        backgroundColor: '#fff',
        boxShadow: '0px 2px 5px rgba(142, 123, 87, 0.16)',
        borderRadius: 10,
        padding: 20,
        marginTop: 30,
    },
    btn: {
        padding: '12px',
        borderRadius: 10,
    },
    showSupportedBtn: {
        margin: '15px auto 0 auto',
        borderRadius: 50,
        width: 175,
        fontSize: 14,
        fontWeight: 600,
    },
}

const CreateLinkResult = ({ data, error, loading, openSupportedLinks }) => {
    const [ copied, setCopied ] = useState(false)

    const onCopy = () => {
        clearTimeout(onCopy.timer)
        setCopied(true)
        onCopy.timer = setTimeout(() => setCopied(false), 1000)
    }

    if (loading) {
        return (
            <Text
                className={'loading-black'}
                children={'Preparing your smartlink'}
                weight={'bold'}
                size={'p'}
                wrapperStyle={{
                    marginTop: 30,
                    textAlign: 'center',
                }}
            />
        )
    }

    if (error) {
        return (
            <>
                <Text
                    children={(
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Icon type={'Danger'} bold style={{ fontSize: 16, color: '#FF274B' }}/>
                            <div style={{ width: 5 }}/>
                            <div style={{ paddingTop: 1 }}>{error}</div>
                        </div>
                    )}
                    weight={'bold'}
                    size={'p'}
                    color={'red'}
                    wrapperStyle={{
                        marginTop: 30,
                        textAlign: 'center',
                    }}
                />
                {error === 'Invalid link, not a supported social media link'
                    ? (
                        <Button
                            variant={'grey'}
                            style={styles.showSupportedBtn}
                            onDisclose={openSupportedLinks}
                            children={'Show supported links'}
                        />
                    )
                    : null}
            </>
        )
    }

    if (!data) return null

    const link = `${window.location.host}/s/`
    const shareLink = `${window.location.origin}/s/${data.id}`

    return (
        <div style={styles.wrapper}>
            {data.title
                ? (
                    <Text
                        children={data.title}
                        weight={'bold'}
                        size={'h6'}
                    />
                )
                : (
                    <Text
                        children={`${capitalizeString(data.vendor)} url`}
                        weight={'bold'}
                        size={'h6'}
                    />
                )}
            <div style={{ height: 20 }}/>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text
                    children={link}
                    weight={'semiBold'}
                    size={'p'}
                    color={'primary'}
                />
                <div style={{ width: 10 }}/>
                <Text
                    children={data.id}
                    weight={'semiBold'}
                    size={'p'}
                    color={'primary'}
                    wrapperStyle={{
                        backgroundColor: '#F7F9FD',
                        padding: 10,
                        borderRadius: 10,
                        width: '100%',
                    }}
                />
            </div>
            <div style={{ height: 30 }}/>
            <CopyToClipboard text={shareLink}>
                <div>
                    <Button
                        variant={copied ? 'soft' : 'default'}
                        children={copied ? 'Copied' : 'Copy link'}
                        style={{
                            ...styles.btn,
                            ...(copied
                                ? {
                                    color: '#fff',
                                    backgroundColor: '#20D193',
                                }
                                : {}),
                        }}
                        onDisclose={onCopy}
                    />
                </div>
            </CopyToClipboard>
        </div>
    )
}

CreateLinkResult.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        vendor: PropTypes.string,
        title: PropTypes.string,
    }),
    error: PropTypes.string,
    loading: PropTypes.bool,
    openSupportedLinks: PropTypes.func,
}

CreateLinkResult.defaultProps = {
    data: null,
    error: null,
    loading: false,
    openSupportedLinks: () => null,
}

export default CreateLinkResult
