
import PropTypes from 'prop-types'

import LoadingUI from './LoadingUI'
import ErrorUI from './ErrorUI'
import { DashboardPage } from 'pages'

const HomeUI = ({ isValid, hasLoaded }) => {
    if (isValid === false) return <ErrorUI />
    if (isValid === null || !hasLoaded) return <LoadingUI />
    return <DashboardPage />
}

HomeUI.propTypes = {
    isValid: PropTypes.bool,
    hasLoaded: PropTypes.bool,
}

HomeUI.defaultProps = {
    isValid: null,
    hasLoaded: false,
}

export default HomeUI
