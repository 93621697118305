/*

    List of known tutorial keys
    Need to be updated every time we introduce a new key

*/

module.exports = [
    'smartlink::onboarding',
    'smartlink::landing',
]
