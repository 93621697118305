import PropTypes from 'prop-types'
import { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

import Icon from 'components/Iconly'
import Text from 'components/Text'

const StyledMenu = withStyles({
    paper: {
        boxShadow: '0px 0px 18px rgba(205, 213, 231, 0.3)',
        marginLeft: -15,
        borderRadius: 10,
    },
    list: {
        padding: 0,
        margin: 0,
        width: 150,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))

/*
    Tab
        0 = 60m
        1 = 48h
        2 = 7 days
        3 = 30 days
*/

const tabs = [
    { key: 3, value: '30 days' },
    { key: 2, value: '7 days' },
    { key: 1, value: '48 hours' },
    // { key: 0, value: '60 minutes' },
]

const tabsMap = {
    3: '30 days',
    2: '7 days',
    1: '48 hours',
    // 0: '60 minutes',
}

const Tabs = ({ activeTab, setActiveTab }) => {
    const [ el, setEl ] = useState(null)

    return (
        <div>
            <Text
                color={'primary'}
                weight={'semiBold'}
                size={'p'}
                onClick={(e) => setEl(e.currentTarget)}
                children={(
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ marginTop: -2 }}>{tabsMap[activeTab]}</div>
                        <div style={{ width: 2.5 }}/>
                        <Icon type={'Arrow-Down-2'} bold style={{ fontSize: 16, color: '#2772FF' }}/>
                    </div>
                )}
            />
            <StyledMenu
                id="customized-menu"
                autoFocus={false}
                anchorEl={el}
                open={Boolean(el)}
                onClose={() => setEl(null)}
            >
                {tabs.map((i, idx) => (
                    <MenuItem
                        key={i.key}
                        onClick={() => {
                            setActiveTab(i.key)
                            setEl(null)
                        }}
                        children={i.value}
                        style={{
                            borderTop: idx === 0 ? 'none' : '1px solid #DADADA',
                            fontSize: 14,
                            fontWeight: activeTab === i.key ? 600 : 200,
                            color: activeTab === i.key ? '#2772FF' : '#1B1F27',
                        }}
                    />
                ))}
            </StyledMenu>
        </div>
    )
}

Tabs.propTypes = {
    activeTab: PropTypes.number,
    setActiveTab: PropTypes.func,
}

Tabs.defaultProps = {
    activeTab: 0,
    setActiveTab: () => null,
}

export default Tabs
