
import { connect } from 'react-redux'

import { linksService } from 'features/feature-links'

import DashboardUI from './components/DashboardUI'
import LinkPage from './LinkPage'

const mapState = ({ links, auth }) => ({
    list: links.list,
    hits: links.hits,
    limit: links.limit,
    isPro: auth.isPro,
})

const mapDispatch = (dispatch) => ({
    addLink: (url) => dispatch(linksService.add(url)),
    openLink: (linkId) => {
        dispatch({
            type: '@stacklayout::open',
            payload: {
                component: LinkPage,
                props: { linkId, direction: 'bottom' },
            },
        })
    },
})

export default connect(mapState, mapDispatch)(DashboardUI)
