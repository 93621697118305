/**
 * Provides ways to use and sense the network
 *
 */

import listener from './links.listener'
import reducer from './links.reducer'

export const reducers = { links: reducer }
export const services = []
export const listeners = [listener]

export * as linksService from './links.service'
