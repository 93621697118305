
import PropTypes from 'prop-types'

import Text from 'components/Text'

import VendorIcon from './VendorIcon'
import { convertNumber } from 'lib/numbers'
import Iconly from 'components/Iconly'
import { capitalizeString } from 'lib/strings'

const styles = {
    wrapper: {},
    itemWrapper: {
        marginBottom: 10,
        boxShadow: '0px 0px 18px rgba(205, 213, 231, 0.24)',
        borderRadius: 10,
        padding: 15,
        width: '100%',
        overflow: 'hidden',
    },
    itemInfoWrapper: {
        display: 'flex',
        // alignItems: 'center',
    },
    btn: {
        padding: '5px 15px',
        borderRadius: 50,
        fontSize: 12,
    },
    bottomText: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}

const LinkItem = ({ item, openLink }) => {
    const link = `${window.location.host}/s/${item.id}`

    return (
        <div style={styles.itemWrapper} onClick={openLink}>
            <div style={styles.itemInfoWrapper}>
                <div style={{ width: 25 }}>
                    <VendorIcon vendor={item.vendor} size={25}/>
                </div>
                <div style={{ paddingLeft: 10, width: '90%' }}>
                    <Text
                        children={item.title || capitalizeString(item.vendor)}
                        weight={'bold'}
                        size={'p'}
                        truncated
                    />
                </div>
            </div>
            <div style={styles.bottomText}>
                <Text
                    children={link}
                    color={'primary'}
                    size={'p2'}
                />
                <Text
                    children={(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Iconly type={'Chart'} bold style={{ fontSize: 14, color: '#A8B0C0' }}/>
                            <div style={{ width: 5 }}/>
                            <div style={{ marginTop: -1 }}>{convertNumber(item.hits)}</div>
                        </div>
                    )}
                    color={'grey2'}
                    size={'p'}
                />
            </div>
        </div>
    )
}

const LinksList = ({ list, openLink }) => {
    return (
        <div style={styles.wrapper}>
            {list[0]
                ? (
                    <>
                        <Text
                            children={'Latest created smartlinks'}
                            size={'h6'}
                            weight={'bold'}
                        />
                        <div style={{ height: 20 }}/>
                    </>
                )
                : null}
            {list.map(i => <LinkItem key={i.id} item={i} openLink={() => openLink(i.id)}/>)}
        </div>
    )
}

LinksList.propTypes = {
    list: PropTypes.array,
    openLink: PropTypes.func,
}

LinksList.defaultProps = {
    list: [],
    openLink: () => null,
}

LinkItem.propTypes = {
    item: PropTypes.object.isRequired,
    openLink: PropTypes.func.isRequired,
}

export default LinksList
