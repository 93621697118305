
import PropTypes from 'prop-types'
import { useState, useRef } from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import { isEmptyString, hasValidUname } from 'lib/strings'
import Icon from 'components/Iconly'

const styles = {
    wrapper: {
        padding: 15,
    },
    innerWrapper: {
        backgroundColor: '#FFFFFF',
        padding: 15,
        border: '1px solid #EBF0F9',
        borderRadius: 10,
    },
    input: {
        fontWeight: 500,
        color: '#69707E',
        border: 'none',
        padding: 0,
        margin: 0,
        fontSize: 16,
        backgroundColor: 'none',
        outline: 'none',
    },
}

const EditLink = ({ link, onDisclose, close }) => {
    if (!link) return null

    const key = link.id
    const [ value, setValue ] = useState(key)
    const [ error, setError ] = useState(null)
    const ref = useRef(null)

    const handleDisclose = async () => {
        if (key === value) return close()
        if (isEmptyString(value)) return setError('Back-half can\'t be empty')
        if (!hasValidUname(value)) return setError('invalid Back-half format')

        try {
            await onDisclose(value)
            close()
        } catch (err) {
            if (err.message === 'already taken') {
                return setError('Back-half already taken')
            }

            setError('Something went wrong, please try again')
        }
    }

    return (
        <div style={styles.wrapper}>
            <div style={styles.innerWrapper}>
                <Text
                    children={'Customize Back-half'}
                    size={'p2'}
                    weight={'medium'}
                />
                <div style={{ height: 15 }}/>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text
                        children={`${window.location.host}/s/`}
                        size={'h6'}
                        weight={'medium'}
                        wrapperStyle={{ marginTop: 2 }}
                    />
                    <input
                        ref={ref}
                        type={'text'}
                        onChange={(e) => {
                            if (error) setError(null)
                            setValue(e.target.value)
                        }}
                        value={value}
                        onKeyPress={(e) => e.code === 'Enter' ? handleDisclose() : null}
                        onClick={() => {
                            if (!ref.current) return
                            ref.current.focus()
                        }}
                        style={styles.input}
                    />
                </div>
            </div>
            <div style={{ height: 15 }}/>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {error
                    ? (
                        <Text
                            children={(
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Icon type={'Danger'} bold style={{ fontSize: 16, color: '#FF274B' }}/>
                                    <div style={{ width: 5 }}/>
                                    <div style={{ paddingTop: 1 }}>{error}</div>
                                </div>
                            )}
                            weight={'bold'}
                            size={'p'}
                            color={'red'}
                        />
                    )
                    : <div />}
                <div style={{ width: 10 }}/>
                <Button
                    children={'Save'}
                    style={{ borderRadius: 100, float: 'right', width: 100 }}
                    onDisclose={handleDisclose}
                />
            </div>
        </div>
    )
}

EditLink.propTypes = {
    link: PropTypes.object,
    onDisclose: PropTypes.func,
    close: PropTypes.func,
}

EditLink.defaultProps = {
    link: null,
    onDisclose: () => null,
    close: () => null,
}

export default EditLink
