
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import Text from 'components/Text'
import Button from 'components/Button'
// import Iconly from 'components/Iconly'

const styles = {
    modalStyles: {
        height: '100%',
        borderRadius: 15,
        textAlign: 'center',
        maxWidth: 450,
    },
    wrapper: {
        backgroundColor: '#fff',
        margin: 15,
        padding: '0 15px',
        overflow: 'hidden',
        borderRadius: 15,
    },

    button: {
        height: 50,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
}

const ProAlert = ({
    isOpen,
    openPro,
    graphics,
    title,
    description,
    close,
}) => {
    return (
        <Modal
            innerStyles={styles.modalStyles}
            open={isOpen}
            onClose={close}
        >
            <div style={styles.wrapper}>
                <div style={{ height: 30 }}/>
                {graphics}
                <div style={{ height: 20 }}/>
                <Text
                    children={title}
                    size={'h6'}
                    weight={'bold'}
                    color={'black'}
                />
                <div style={{ height: 15 }}/>
                <Text
                    children={description}
                    size={'p'}
                    color={'text'}
                />
                <div style={{ height: 20 }}/>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Button
                        style={styles.button}
                        variant={'bordered'}
                        onDisclose={close}
                        children={'OK'}
                    />
                    <div style={{ width: 15 }}/>
                    <Button
                        style={styles.button}
                        variant={'default'}
                        onDisclose={openPro}
                        children={'Upgrade'}
                    />
                </div>
                <div style={{ height: 30 }}/>
            </div>
        </Modal>
    )
}

ProAlert.propTypes = {
    isOpen: PropTypes.bool,
    openPro: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    graphics: PropTypes.node,
    close: PropTypes.func,
}

ProAlert.defaultProps = {
    isOpen: false,
    openPro: () => null,
    close: () => null,
    title: null,
    description: null,
    graphics: null,
}

export default ProAlert
