
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'

import { linksService } from 'features/feature-links'

import LinkUI from './components/LinkUI'

const mapState = () => ({})

const mapDispatch = (dispatch, { linkId }) => ({
    load: () => dispatch(linksService.findOwnerKey(linkId)),
    onEdit: (key, newKey) => dispatch(linksService.editOwnerKey(key, newKey)),
    close: () => dispatch({ type: '@stacklayout::close' }),
})

// eslint-disable-next-line
const LinkPage = ({ load, close, onEdit }) => {
    const [ data, setData ] = useState(null)
    const [ error, setError ] = useState(null)
    useEffect(() => {
        load()
            .then(setData)
            .catch((err) => setError(err.message))
    }, [])

    const handleEdit = async (newKey) => {
        await onEdit(data.link.id, newKey)
        data.link.id = newKey
    }

    return (
        <LinkUI
            data={data}
            error={error}
            close={close}
            onEdit={handleEdit}
        />
    )
}

export default connect(mapState, mapDispatch)(LinkPage)
