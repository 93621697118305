
import { useState } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text'

import AnalyticsChart from './AnalyticsChart'
import AnalyticsMetrics from './AnalyticsMetrics'
import AnalyticsTabs from './AnalyticsTabs'

const styles = {
    wrapper: {
        backgroundColor: '#fff',
        borderRadius: 10,
        border: '1px solid #DADADA',
    },
    line: {
        height: 1,
        backgroundColor: '#DADADA',
        width: '100%',
    },
}

/*
    Tab
        0 = 60m
        1 = 48h
        2 = 7 days
        3 = 30 days
*/

const tabKey = {
    // 0: 'hour',
    1: 'twoDays',
    2: 'week',
    3: 'month',
}

const Analytics = ({ hits }) => {
    const [ tab, setTab ] = useState(3)

    if (!hits) return null

    const getData = () => {
        // if (tab === 0) return hits.hour.list
        if (tab === 1) return hits.twoDays.list
        if (tab === 2) return hits.week.list
        if (tab === 3) return hits.month.list
        return []
    }

    return (
        <div style={styles.wrapper}>
            <div style={{ height: 15 }}/>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 15px' }}>
                <Text
                    children={'Overview'}
                    size={'h6'}
                    weight={'semiBold'}
                />
                <AnalyticsTabs activeTab={tab} setActiveTab={setTab}/>
            </div>
            <div style={{ height: 15 }}/>
            <div style={styles.line}/>
            <div style={{ height: 15 }}/>
            <AnalyticsMetrics clicks={hits[tabKey[tab]].value} />
            <div style={{ height: 15 }}/>
            <div style={styles.line}/>
            <div style={{ height: 15 }}/>
            <AnalyticsChart
                hasData={hits[tabKey[tab]].value !== 0}
                data={getData()}
                tab={tab}
            />
            <div style={{ height: 15 }}/>
        </div>
    )
}

Analytics.propTypes = {
    hits: PropTypes.object,
}

Analytics.defaultProps = {
    hits: null,
}

export default Analytics
