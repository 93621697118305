
import Text from 'components/Text'
import Icon from 'components/Iconly'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    innerWrapper: {
        borderRadius: 10,
        margin: '30px 15px',
        padding: '30px 15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: 450,
    },
}

const ErrorUI = () => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.innerWrapper}>
                <Text
                    children={(
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Icon type={'Danger'} bold style={{ fontSize: 18, color: '#FF274B' }}/>
                            <div style={{ width: 5 }}/>
                            <div style={{ paddingTop: 1 }}>Details not found</div>
                        </div>
                    )}
                    weight={'bold'}
                    size={'h6'}
                    color={'red'}
                />
            </div>
        </div>
    )
}

export default ErrorUI
