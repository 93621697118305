
import PropTypes from 'prop-types'

import Text from 'components/Text'

import VendorIcon from './VendorIcon'

const styles = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    vendorsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

const vendors = [
    'youtube',
    'instagram',
    'snapchat',
    'tiktok',
    'twitch',
    'twitter',
    'spotify',
    'facebook',
]

const SupportedVendors = ({ openSupportedLinks }) => {
    return (
        <div style={styles.wrapper} onClick={openSupportedLinks}>
            <Text
                children={'Supported link formats:'}
                color={'grey2'}
                size={'p2'}
            />
            <div style={styles.vendorsWrapper}>
                {vendors.map(i => (
                    <div key={i} style={{ marginLeft: 10 }}>
                        <VendorIcon size={15} vendor={i} />
                    </div>
                ))}
            </div>
        </div>
    )
}

SupportedVendors.propTypes = {
    openSupportedLinks: PropTypes.func,
}

SupportedVendors.defaultProps = {
    openSupportedLinks: () => null,
}

export default SupportedVendors
