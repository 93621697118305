
import { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'
import { isIOS } from 'react-device-detect'

import './input.css'

const styles = {
    wrapper: {
        position: 'relative',
    },
    input: {
        color: '#1B1F27',
        backgroundColor: '#fff',
        border: '1px solid #EBF0F9',
        outline: 'none',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 10,
        width: '100%',
        fontSize: 16,
        fontWeight: 500,
        height: 50,
    },
    leftIcon: {
        position: 'absolute',
        zIndex: 10,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        left: 0,
        fontSize: 18,
        top: 0,
    },
    rightIcon: {
        position: 'absolute',
        zIndex: 10,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 15,
        right: 0,
        fontSize: 18,
        top: 0,
    },
}

// eslint-disable-next-line react/display-name
const TextInput = forwardRef(({ type, onChange, value, style, leftIcon, rightIcon, isError, onEnter, ...props }, ref) => {
    ref = ref || useRef(null)

    const iconClick = (e) => {
        try {
            e.preventDefault()
            e.stopPropagation()
            ref.current.focus()
        } catch (err) {} // eslint-disable-line
    }
    return (
        <div style={styles.wrapper}>
            {leftIcon
                ? (
                    <div style={styles.leftIcon} onClick={iconClick}>
                        {leftIcon}
                    </div>
                )
                : null}
            <input
                ref={ref}
                className={'input text-input'}
                type={type || 'text'}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                onKeyPress={(e) => e.code === 'Enter' ? onEnter() : null}
                onClick={() => {
                    if (!ref.current) return
                    ref.current.focus()
                    window.cordova && isIOS && ref.current.focus()
                }}
                style={{
                    ...styles.input,
                    ...(style || {}),
                    ...(leftIcon ? { paddingLeft: 40 } : {}),
                    ...(rightIcon ? { paddingRight: 40 } : {}),
                    ...(isError ? { border: '2px solid #FF274B' } : {}),
                }}
                {...props}
            />
            {rightIcon
                ? (
                    <div style={styles.rightIcon}>
                        {rightIcon}
                    </div>
                )
                : null}
        </div>
    )
})

TextInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    isError: PropTypes.bool,
    onEnter: PropTypes.func,
    type: PropTypes.string,
}

TextInput.defaultProps = {
    value: '',
    onChange: () => null,
    style: {},
    leftIcon: null,
    rightIcon: null,
    isError: false,
    onEnter: () => null,
    type: null,
}

export default TextInput
