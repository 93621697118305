
import Text from 'components/Text'
import logo from 'resources/images/logo-black.png'
import SadLottie from 'components/Lottie/Sad'
import Button from 'components/Button'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    innerWrapper: {
        // boxShadow: '0px 5px 20px rgba(205, 213, 231, 0.7)',
        borderRadius: 10,
        margin: '30px 15px',
        padding: '30px 15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: 450,
    },
    lottie: {
        width: 150,
    },
}

const ErrorUI = () => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.innerWrapper}>
                <img
                    src={logo}
                    alt={'mysocial'}
                    style={{
                        width: 180,
                    }}
                />
                <div style={{ height: 90 }}/>
                <SadLottie loop style={styles.lottie}/>
                <div style={{ height: 30 }}/>
                <Text
                    children={'Sorry, you can\'t get in'}
                    size={'h5'}
                    weight={'bold'}
                />
                <div style={{ height: 15 }}/>
                <Text
                    children={'You can only access Smartlink through the Mysocial App'}
                    size={'h6'}
                    weight={'normal'}
                    color={'text'}
                />
                <div style={{ height: 30 }}/>
                <Button
                    children={'Open Mysocial'}
                    style={{ padding: '10px 30px' }}
                    onDisclose={() => window.open('https://to.mysocial.io/mysocial/store', 'blank')}
                />
            </div>
        </div>
    )
}

export default ErrorUI
