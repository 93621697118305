
import { runQuery } from 'features/feature-network'
import queries from './graphql'

import { setValues, setTutorial } from './tutorials.reducer'
import knownTutorials from './known-tutorials'

export const disableTutorial = (name) => async (dispatch) => {
    dispatch(setTutorial(name, false))

    try {
        await dispatch(runQuery(queries.disable, { name }))
    } catch (err) {}
}

export const fetchTutorials = () => async (dispatch) => {
    const query = await dispatch(runQuery(queries.getTutorials))
    const data = query.data.session.creatorsApi.getTutorials

    const tutorialsMap = data
        .filter(item => item !== null && knownTutorials.includes(item.name))
        .reduce((acc, curr) => ({ ...acc, [curr.name]: curr.isActive }), {})

    dispatch(setValues(tutorialsMap))
}
