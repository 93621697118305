
import PropTypes from 'prop-types'
import { InstagramIcon, SnapchatIcon, TiktokIcon, YoutubeIcon, TwitchIcon, TwitterIcon, SpotifyIcon } from 'components/Icons'

const VendorIcon = ({ vendor, size }) => {
    switch (vendor) {
        case 'youtube': return <YoutubeIcon size={size}/>
        case 'instagram': return <InstagramIcon size={size}/>
        case 'snapchat': return <SnapchatIcon size={size}/>
        case 'tiktok': return <TiktokIcon size={size}/>
        case 'twitch': return <TwitchIcon size={size}/>
        case 'twitter': return <TwitterIcon size={size}/>
        case 'spotify': return <SpotifyIcon size={size}/>
        default: return null
    }
}

VendorIcon.propTypes = {
    vendor: PropTypes.string,
    size: PropTypes.number,
}

VendorIcon.defaultProps = {
    vendor: null,
    size: 20,
}

export default VendorIcon
