
import { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { isEmptyString } from 'lib/strings'

import CreateLinkInput from './CreateLinkInput'
import CreateLinkResult from './CreateLinkResult'
import SupportedVendors from './SupportedVendors'
import SupportedLinks from './SupportedLinks'
import { validateInput } from '../lib/validate-input'

const CreateLink = ({ addLink, setShowPro }) => {
    const resultTimer = useRef(null)
    const loadingTimer = useRef(null)

    const [ value, setValue ] = useState('')
    const [ vendor, setVendor ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ result, setResult ] = useState(null)
    const [ showSupportedLinks, setShowSupportedLinks ] = useState(false)

    const handleOnChange = (value) => {
        setValue(value)

        if (result) setResult(null)
        if (loading) setLoading(false)
        if (error) setError(null)

        if (!isEmptyString(value)) {
            clearTimeout(resultTimer.current)
            clearTimeout(loadingTimer.current)

            loadingTimer.current = setTimeout(async () => {
                try {
                    const { vendor, url } = await validateInput(value)
                    setVendor(vendor)
                    setLoading(true)
                    value = url
                } catch (err) {
                    clearTimeout(resultTimer.current)
                    setError('Invalid link, not a supported social media link')
                }
            }, 500)
            resultTimer.current = setTimeout(() => validate(value), 1000)
        } else {
            vendor && setVendor('')
        }
    }

    const validate = async (inputValue) => {
        try {
            const linkData = await addLink(inputValue)
            setResult(linkData)
        } catch (err) {
            if (err.message === 'need pro') return setShowPro('locked')
            setError(
                err.message !== 'invalid url'
                    ? 'something went wrong, please try again later'
                    : 'Invalid link, not a supported social media link',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <div className={'create-link'}>
                <CreateLinkInput
                    vendor={vendor}
                    value={value}
                    onChange={handleOnChange}
                />
            </div>
            <div style={{ height: 10 }}/>
            <div className={'supported-vendors'}>
                <SupportedVendors openSupportedLinks={() => setShowSupportedLinks(true)}/>
            </div>
            <CreateLinkResult
                data={result}
                error={error}
                loading={loading}
                openSupportedLinks={() => setShowSupportedLinks(true)}
            />
            <SupportedLinks
                open={showSupportedLinks}
                onClose={() => setShowSupportedLinks(false)}
            />
        </div>
    )
}

CreateLink.propTypes = {
    addLink: PropTypes.func,
    setShowPro: PropTypes.func,
}

CreateLink.defaultProps = {
    addLink: () => null,
    setShowPro: () => null,
}

export default CreateLink
