
import PropTypes from 'prop-types'
import GenerateIcon from './GenerateIcon'

const InstagramIcon = ({ size }) => {
    const body = () => {
        return (
            <>
                <g clipPath="url(#clip0_2396_29345)">
                    <path d="M1.00005 1.08868C-0.257282 2.39468 5.14463e-05 3.78201 5.14463e-05 7.99668C5.14463e-05 11.4967 -0.610615 15.0053 2.58538 15.8313C3.58338 16.088 12.426 16.088 13.4227 15.83C14.7534 15.4867 15.836 14.4073 15.984 12.5253C16.0047 12.2627 16.0047 3.73535 15.9834 3.46735C15.826 1.46268 14.592 0.307344 12.966 0.073344C12.5934 0.0193439 12.5187 0.00334393 10.6067 1.05947e-05C3.82472 0.00334393 2.33805 -0.298656 1.00005 1.08868Z" fill="url(#paint0_linear_2396_29345)"/>
                    <path d="M7.99862 2.09268C5.57796 2.09268 3.27929 1.87734 2.40129 4.13068C2.03862 5.06134 2.09129 6.27001 2.09129 8.00068C2.09129 9.51934 2.04262 10.9467 2.40129 11.87C3.27729 14.1247 5.59462 13.9087 7.99729 13.9087C10.3153 13.9087 12.7053 14.15 13.594 11.87C13.9573 10.93 13.904 9.73934 13.904 8.00068C13.904 5.69268 14.0313 4.20268 12.912 3.08401C11.7786 1.95068 10.246 2.09268 7.99596 2.09268H7.99862ZM7.46929 3.15734C12.5186 3.14934 13.1613 2.58801 12.8066 10.386C12.6806 13.144 10.5806 12.8413 7.99929 12.8413C3.29262 12.8413 3.15729 12.7067 3.15729 7.99801C3.15729 3.23468 3.53062 3.16001 7.46929 3.15601V3.15734ZM11.152 4.13801C10.7606 4.13801 10.4433 4.45534 10.4433 4.84668C10.4433 5.23801 10.7606 5.55534 11.152 5.55534C11.5433 5.55534 11.8606 5.23801 11.8606 4.84668C11.8606 4.45534 11.5433 4.13801 11.152 4.13801ZM7.99862 4.96668C6.32329 4.96668 4.96529 6.32534 4.96529 8.00068C4.96529 9.67601 6.32329 11.034 7.99862 11.034C9.67396 11.034 11.0313 9.67601 11.0313 8.00068C11.0313 6.32534 9.67396 4.96668 7.99862 4.96668ZM7.99862 6.03134C10.602 6.03134 10.6053 9.97001 7.99862 9.97001C5.39596 9.97001 5.39196 6.03134 7.99862 6.03134Z" fill="white"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2396_29345" x1="1.03073" y1="14.9781" x2="15.901" y2="2.108" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FFDD55"/>
                        <stop offset="0.5" stopColor="#FF543E"/>
                        <stop offset="1" stopColor="#C837AB"/>
                    </linearGradient>
                    <clipPath id="clip0_2396_29345">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </>
        )
    }

    return (
        <GenerateIcon
            viewBox={'0 0 16 16'}
            size={size}
            animate={false}
            cmp={body()}
        />
    )
}

InstagramIcon.propTypes = {
    size: PropTypes.number,
}

InstagramIcon.defaultProps = {
    size: 25,
}

export default InstagramIcon
