export default {
    findOwner: `
        query findOwner {
            session {
                links {
                    findOwner
                }
            }
        }
    `,
    findOwnerKey: `
        query findKey ($key: ID!) {
            session {
                links {
                    findOwnerKey (key: $key)
                }
            }
        }
    `,
    editOwnerKey: `
        mutation editOwnerKey ($key: ID! $newKey: ID!) {
            session {
                links {
                    editOwnerKey (key: $key, newKey: $newKey)
                }
            }
        }
    `,
    findKey: `
        query findKey ($key: ID!, $isAndroid: Boolean) {
            links {
                findKeyV2 (key: $key, isAndroid: $isAndroid) {
                    fallback
                    prefix
                }
            }
        }
    `,
    add: `
        mutation add ($url: String!){
            session {
                links {
                    addV2 (url: $url) {
                        id
                        vendor
                        title
                    }
                }
            }
        }
    `,
}
