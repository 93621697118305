
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { useState } from 'react'

import Icon from 'components/Iconly'
import Text from 'components/Text'

import Error from './Error'
import Loading from './Loading'
import InfoContent from './InfoContent'
import EditLink from './EditLink'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        position: 'relative',
        maxWidth: 450,
        backgroundColor: '#FAFAFA',
    },
    header: {
        position: 'fixed',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        padding: 15,
        width: '100%',
        maxWidth: 450,
        backgroundColor: '#fff',
        borderBottom: '1px solid #DDDDDD',
        height: 55,
    },
    body: {
        height: 'calc(100% - 55px)',
        width: '100%',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },
}

const LinkUI = ({ data, error, close, onEdit }) => {
    const [ isEdit, setIsEdit ] = useState(false)

    const renderBody = () => {
        if (!data && !error) return <Loading />
        if (error) return <Error />
        return (
            <SwipeableViews
                index={isEdit ? 1 : 0}
                disabled
            >
                <InfoContent
                    link={data.link}
                    hits={data.hits}
                    openEdit={() => setIsEdit(true)}
                />
                {isEdit
                    ? (
                        <EditLink
                            link={data.link}
                            onDisclose={onEdit}
                            close={() => setIsEdit(false)}
                        />
                    )
                    : <div />}
            </SwipeableViews>
        )
    }

    return (
        <div style={styles.wrapper}>
            <div style={styles.header}>
                <div style={{ padding: 15, margin: -15 }}
                >
                    <div style={{ height: 3 }}/>
                    {isEdit
                        ? (
                            <Icon
                                type={'Arrow-Left'}
                                style={{ fontSize: 20 }}
                                onClick={() => setIsEdit(false)}
                            />
                        )
                        : (
                            <Icon
                                type={'Arrow-Down-2'}
                                style={{ fontSize: 20 }}
                                onClick={close}
                            />
                        )}
                </div>
                <div style={{ width: 10 }}/>
                <Text
                    children={'Smartlink Details'}
                    size={'p'}
                    weight={'bold'}
                />
            </div>
            <div style={{ height: 55 }}/>
            <div style={styles.body}>
                {renderBody()}
            </div>
        </div>
    )
}

LinkUI.propTypes = {
    data: PropTypes.object,
    error: PropTypes.string,
    close: PropTypes.func,
    onEdit: PropTypes.func,
}

LinkUI.defaultProps = {
    data: null,
    error: null,
    close: () => null,
    onEdit: () => null,
}

export default LinkUI
