
import PropTypes from 'prop-types'
import GenerateIcon from './GenerateIcon'

const FacebookIcon = ({ size }) => {
    const body = () => {
        return (
            <>
                <defs>
                    <linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="35.80825" y1="35.80825" x2="145.537079" y2="145.537079" gradientTransform="matrix(0.334884,0,0,0.334884,-4.8,-4.8)">
                        <stop offset="0" stopColor="rgb(16.470589%,64.313728%,95.686275%)" stopOpacity="1"/>
                        <stop offset="1" stopColor="rgb(0%,47.843137%,85.09804%)" stopOpacity="1"/>
                    </linearGradient>
                </defs>
                <g id="surface87898715">
                    <path stroke="none" fillRule="nonzero" fill="url(#linear0)" d="M 24 0 C 10.746094 0 0 10.746094 0 24 C 0 37.253906 10.746094 48 24 48 C 37.253906 48 48 37.253906 48 24 C 48 10.746094 37.253906 0 24 0 Z M 24 0 "/>
                    <path stroke="none" fillRule="nonzero" fill="rgb(100%,100%,100%)" fillOpacity="1" d="M 27.25 30.359375 L 33.460938 30.359375 L 34.433594 24.050781 L 27.25 24.050781 L 27.25 20.601562 C 27.25 17.980469 28.105469 15.65625 30.558594 15.65625 L 34.496094 15.65625 L 34.496094 10.152344 C 33.804688 10.058594 32.339844 9.855469 29.574219 9.855469 C 23.796875 9.855469 20.410156 12.90625 20.410156 19.855469 L 20.410156 24.054688 L 14.472656 24.054688 L 14.472656 30.363281 L 20.410156 30.363281 L 20.410156 47.703125 C 21.585938 47.878906 22.777344 48 24 48 C 25.105469 48 26.183594 47.898438 27.25 47.753906 Z M 27.25 30.359375 "/>
                </g>
            </>
        )
    }

    return (
        <GenerateIcon
            viewBox={'0 0 48 48'}
            size={size}
            animate={false}
            cmp={body()}
        />
    )
}

FacebookIcon.propTypes = {
    size: PropTypes.number,
}

FacebookIcon.defaultProps = {
    size: 25,
}

export default FacebookIcon
