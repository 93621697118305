/**
 * Provides ways to use and sense the network
 *
 */

import reducer from './auth.reducer'
import * as service from './auth.service'

export const reducers = { auth: reducer }
export const services = [service]
export const listeners = []

export { validate } from './auth.service'
export { SET_IDENTITY } from './auth.reducer'
