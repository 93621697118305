
import loadable from '@loadable/component'

export const SharePage = loadable(() => import('./SharePage'))
export const ShareMysocialPage = loadable(() => import('./ShareMysocialPage'))
export const ShareMysocialStorePage = loadable(() => import('./ShareMysocialStorePage'))
export const OnboardingPage = loadable(() => import('./OnboardingPage'))

export { default as HomePage } from './HomePage'
export { default as DashboardPage } from './DashboardPage'
