
export const initialState = {
    values: {},
}

/**
 * Actions
 */

export const SET_VALUES = 'setValues@tutorial'
export const SET_TUTORIAL = 'setTutorial@tutorial'

export const setValues = (values) => ({
    type: SET_VALUES,
    payload: { values },
})

export const setTutorial = (tutorial, value) => ({
    type: SET_TUTORIAL,
    payload: { tutorial, value },
})

/**
 * Handlers
 */

export const actionHandlers = {
    '@reset': () => ({ ...initialState }),
    [SET_VALUES]: (state, { payload }) => ({
        ...state,
        values: {
            ...state.values,
            ...payload.values,
        },
    }),
    [SET_TUTORIAL]: (state, { payload }) => ({
        ...state,
        values: {
            ...state.values,
            [payload.tutorial]: payload.value,
        },
    }),
}

export const reducer = (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return handler ? handler(state, action) : state
}

export default reducer
