
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import Text from 'components/Text'
import { CrossIcon } from 'components/Icons'
import VendorIcon from './VendorIcon'

const styles = {
    wrapper: {
        padding: '30px 15px',
        overflow: 'scroll',
        WebkitOverflowScrolling: 'touch',
        height: '100%',
    },
    header: {
        height: 50,
        width: '100%',
        backgroundColor: '#FFF',
        padding: 15,
        position: 'absolute',
        zIndex: 1,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    vendorTitle: {
        paddingBottom: 15,
        paddingTop: 30,
        display: 'flex',
        alignItems: 'center',
    },
}

const SupportedLinks = ({ onClose, open }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <div
                style={styles.header}>
                <Text children={'Supported link formats'} size={'h5'} weight={'bold'}/>
                <div onClick={onClose}>
                    <CrossIcon size={20}/>
                </div>
            </div>
            <div style={styles.wrapper}>
                <div style={{ height: 50 }}/>
                <div>
                    <div style={{ ...styles.vendorTitle, paddingTop: 0 }}>
                        <VendorIcon vendor={'instagram'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'Instagram'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://instagram.com/reel/</span><b><code>{'<reel>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://instagram.com/p/</span><b><code>{'<post>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://instagram.com/tv/</span><b><code>{'<post>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://instagram.com/</span><b><code>{'<profile>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'youtube'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'YouTube'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtu.be/</span><b><code>{'<video>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtube.com/watch?v=</span><b><code>{'<video>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtube.com/shorts/</span><b><code>{'<short>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtube.com/channel/</span><b><code>{'<channel id>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtube.com/c/</span><b><code>{'<channel name>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtube.com/user/</span><b><code>{'<channel name>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://youtube.com/@</span><b><code>{'<channel name>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'tiktok'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'TikTok'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://tiktok.com/@<b><code>{'<profile>'}</code></b>/video/</span><b><code>{'<video>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://tiktok.com/@</span><b><code>{'<profile>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://vm.tiktok.com/</span><b><code>{'<id>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'snapchat'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'Snapchat'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://snapchat.com/add/</span><b><code>{'<profile>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'twitter'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'Twitter'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://twitter.com/</span><b><code>{'<profile>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://twitter.com/<b><code>{'<profile>'}</code></b>/status/</span><b><code>{'<tweet>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'twitch'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'Twitch'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://twitch.tv/</span><b><code>{'<profile>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://twitch.tv/videos/</span><b><code>{'<video>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'spotify'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'Spotify'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://open.spotify.com/user/</span><b><code>{'<key>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://open.spotify.com/episode/</span><b><code>{'<key>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://open.spotify.com/playlist/</span><b><code>{'<key>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://open.spotify.com/track/</span><b><code>{'<key>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://open.spotify.com/show/</span><b><code>{'<key>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://open.spotify.com/artist/</span><b><code>{'<key>'}</code></b>
                    </Text>
                </div>
                <div>
                    <div style={styles.vendorTitle}>
                        <VendorIcon vendor={'facebook'}/>
                        <div style={{ width: 7.5 }}/>
                        <Text
                            children={'Facebook'}
                            size={'h5'}
                            weight={'bold'}
                        />
                    </div>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://facebook.com/</span><b><code>{'<page> or <profile>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://facebook.com/groups/</span><b><code>{'<group>'}</code></b>
                    </Text>
                    <Text
                        size={'p'}
                        color={'text'}
                        weight={'medium'}
                        wrapperStyle={{ paddingBottom: 5 }}
                    >
                        <span>https://facebook.com/profile.php?id=</span><b><code>{'<profile>'}</code></b>
                    </Text>
                </div>
            </div>
        </Modal>
    )
}

SupportedLinks.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

SupportedLinks.defaultProps = {
    open: false,
    onClose: () => null,
}

export default SupportedLinks
