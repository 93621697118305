
import { Switch, Route } from 'react-router-dom'
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4'

import { HomePage, SharePage, ShareMysocialPage, ShareMysocialStorePage } from './pages'
import StackLayout from './containers/StackLayout'

// ReactGA.initialize('UA-102112932-9')
// ReactGA.pageview(window.location.pathname + window.location.search)
ReactGA.initialize('G-6RFGNC9G8X')

const App = () => {
    return (
        <>
            <Switch>
                <Route path={'/mysocial/store'} component={ShareMysocialStorePage} />
                <Route path={'/mysocial'} component={ShareMysocialPage} />
                <Route path={'/share/:key?'} component={SharePage} />
                <Route path={'/s/:key?'} component={SharePage} />
                <Route exact path={'/'} component={HomePage} />
            </Switch>
            <StackLayout />
        </>
    )
}

export default App
