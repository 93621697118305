import Text from 'components/Text'
import { ReactComponent as GraphicElement } from 'resources/images/ProCTAGraphics.svg'

const styles = {
    wrapper: {
        width: '100%',
        background: '#FFF8ED',
        position: 'relative',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        borderRadius: 15,
        overflow: 'hidden',
    },
    graphicElement: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
    },
    btn: {
        background: '#FCBB4C',
        zIndex: 2,
        height: '100%',
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 5,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 700,
    },
}

const GetProCTA = () => {
    return (
        <div style={styles.wrapper} onClick={() => window.open('https://app.mysocial.io/open/pro')}>
            <Text
                children={'Get Unlimited Smartlinks'}
                wrapperStyle={{ zIndex: 15 }}
                size={'p'}
                weight={'semiBold'}
            />
            <div style={styles.btn}>
                Upgrade
            </div>
            <GraphicElement
                style={styles.graphicElement}
            />
        </div>
    )
}

export default GetProCTA
