
import { parse } from 'query-string'

import { runQuery } from 'features/feature-network'
import { setIdentity, setIsValid } from './auth.reducer'

export const validate = (token) => async (dispatch, getState) => {
    const q = `
        query validate ($token: ID) {
            auth {
                validate (token: $token)
            }
        }
    `

    try {
        const res = await dispatch(runQuery(q, { token }))
        const identity = res.data.auth.validate
        dispatch(setIdentity(identity))
        setTimeout(() => dispatch(setIsValid(true)), 1000)
    } catch (err) {
        dispatch(setIsValid(false))
    }
}

export const start = () => async (dispatch) => {
    const { token } = parse(window.location.search)
    await dispatch(validate(token))
}
