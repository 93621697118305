
import PropTypes from 'prop-types'
import { useState } from 'react'

import { OnboardingPage } from 'pages'

import Header from './components/Header'
import CreateLink from './components/CreateLink'
import Analytics from '../AnalyticsCmp'
import LinksList from './components/LinksList'
import ProPopup from './components/ProPopup'
import GetProCTA from './components/GetProCTA'

const styles = {
    outerWrapper: {
        backgroundColor: '#FAFAFA',
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },
    wrapper: {
        padding: '0px 15px 30px 15px',
        maxWidth: 450,
        margin: '0 auto',
        overflowX: 'hidden',
        backgroundColor: '#FAFAFA',
    },
}

const DashboardUI = ({ list, hits, isPro, addLink, limit, openLink, disableOnboarding }) => {
    const [ showPro, setShowPro ] = useState(null)

    return (
        <div style={styles.outerWrapper}>
            <div id={'DashboardUI-top'} />
            <div style={styles.wrapper}>
                <Header
                    isPro={isPro}
                    limit={limit}
                    setShowPro={setShowPro}
                />
                {limit === 0 && !isPro
                    ? <>
                        <div style={{ height: 50 }} />
                        <GetProCTA />
                    </>
                    : null
                }
                <div style={{ height: 50 }} />
                <CreateLink addLink={addLink} setShowPro={setShowPro} />
                {disableOnboarding ? null : <OnboardingPage />}
                <div style={{ height: 50 }}/>
                <div className={'analytics'}>
                    <Analytics hits={hits} list={list}/>
                </div>
                <div style={{ height: 30 }}/>
                <LinksList list={list} openLink={openLink}/>
                <ProPopup
                    limit={limit}
                    showPro={showPro}
                    close={() => setShowPro(null)}
                />
            </div>
        </div>
    )
}

DashboardUI.propTypes = {
    list: PropTypes.array,
    hits: PropTypes.object,
    isPro: PropTypes.bool,
    addLink: PropTypes.func,
    limit: PropTypes.number,
    openLink: PropTypes.func,
    disableOnboarding: PropTypes.bool,
}

DashboardUI.defaultProps = {
    disableOnboarding: false,
    limit: 0,
    addLink: () => null,
    openLink: () => null,
    isPro: false,
    hits: {},
    list: [],
}

export default DashboardUI
