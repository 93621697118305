
import PropTypes from 'prop-types'

import Text from 'components/Text'
import proBadge from 'resources/images/pro-badge.png'

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        margin: '0px -15px',
        borderBottom: '1px solid #DDDDDD',
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: 30,
        height: 30,
        borderRadius: '100%',
    },
    proBadge: {
        width: 50,
    },
    limitWrapper: {
        backgroundColor: 'rgba(235, 240, 249, 0.4)',
        borderRadius: 5,
        padding: '5px 15px',
    },
}

const Header = ({ limit, isPro, setShowPro }) => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.logoWrapper}>
                <img
                    src={'https://generalcdn.mysocial.io/smartlink.png'}
                    style={styles.logo}
                />
                <div style={{ width: 10 }}/>
                <Text
                    children={'SmartLink'}
                    weight={'bold'}
                    size={'h3'}
                />
            </div>
            <div>
                {isPro
                    ? (
                        <img
                            src={proBadge}
                            style={styles.proBadge}
                        />
                    )
                    : (
                        <div
                            className={'links-limit'}
                            style={styles.limitWrapper}
                            onClick={() => setShowPro('limit')}
                        >
                            <Text
                                children={`${limit} smartlink${limit === 1 ? '' : 's'} left`}
                                color={'grey2'}
                                size={'p'}
                                weight={'medium'}
                            />
                        </div>
                    )}
            </div>
        </div>
    )
}

Header.propTypes = {
    limit: PropTypes.number,
    isPro: PropTypes.bool,
    setShowPro: PropTypes.func,
}

Header.defaultProps = {
    limit: 0,
    isPro: false,
    setShowPro: () => null,
}

export default Header
