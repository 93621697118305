import PropTypes from 'prop-types'
import { BarChart } from 'recharts/lib/chart/BarChart'
import { Bar } from 'recharts/lib/cartesian/Bar'
import { XAxis } from 'recharts/lib/cartesian/XAxis'
import { CartesianGrid } from 'recharts/lib/cartesian/CartesianGrid'
import { Tooltip } from 'recharts/lib/component/Tooltip'
import { ResponsiveContainer } from 'recharts/lib/component/ResponsiveContainer'

import { convertNumber } from 'lib/numbers'
import Text from 'components/Text'

const styles = {
    wrapper: {
        padding: '0 15px',
        position: 'relative',
    },
    tooltipContent: {
        border: 'none',
        margin: 0,
        padding: '10px 10px',
        borderRadius: 20,
        backgroundColor: '#fff',
        boxShadow: '0px -4px 20px rgba(205, 213, 231, 0.25)',
        textAlign: 'center',
    },
    tooltipItem: {
        fontSize: 14,
        fontWeight: 700,
        display: 'inline-block',
        color: '#2772FF',
    },
    tooltipLabel: {
        fontSize: 12,
        color: '#BCC6D9',
    },
    tick: {
        fill: '#BCC6D9',
        fontSize: 12,
    },
    negativeColor: '#FFD9DF',
    positiveColor: '#2772FF',
    noData: {
        position: 'absolute',
        zIndex: 50,
        top: '40%',
        left: 0,
        right: 0,
        margin: '0 auto',
        textAlign: 'center',
    },
}

const ChartBar = ({ data, tab, hasData }) => {
    const reversedData = data.map(i => ({ value: i }))
    const dataLength = reversedData.length
    return (
        <div style={styles.wrapper}>
            {!hasData
                ? (
                    <div style={styles.noData}>
                        <Text
                            children={'no data available'}
                            color={'grey3'}
                            weight={'semiBold'}
                            size={'p'}
                        />
                    </div>
                )
                : null}
            <ResponsiveContainer height={175} width={'100%'}>
                <BarChart data={reversedData}>
                    <XAxis
                        tickFormatter={(value) => {
                            if (!dataLength) return value === 0 ? '1d' : '2d'
                            if (tab === 0) return `${value + 1}m`
                            if (tab === 1) return `${value + 1}h`
                            return `${value + 1}d`
                        }}
                        axisLine={false}
                        tickLine={false}
                        tickMargin={10}
                        reversed
                        tick={styles.tick}
                    />
                    <CartesianGrid
                        vertical={false}
                        strokeDasharray={'5'}
                        stroke={'#EBF0F9'}
                        strokeWidth={1}
                    />
                    <Tooltip
                        separator={''}
                        contentStyle={styles.tooltipContent}
                        cursor={{ fill: '#EBF0F9' }}
                        itemStyle={styles.tooltipItem}
                        labelStyle={styles.tooltipLabel}
                        formatter={(value, name, props) => {
                            if (value === null) return null
                            return convertNumber(value, 2)
                        }}
                        labelFormatter={(value) => {
                            if (tab === 0) return `${value + 1}m ago`
                            if (tab === 1) return `${value + 1}h ago`
                            return `${value + 1}d ago`
                        }}
                    />
                    <Bar
                        dataKey={'value'}
                        fill={styles.positiveColor}
                        stackId={'stack'}
                        barSize={12}
                        name={' '}
                        radius={[ 10, 10, 0, 0 ]}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

ChartBar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.number),
    tab: PropTypes.number,
    hasData: PropTypes.bool,
}

ChartBar.defaultProps = {
    data: [],
    tab: 0,
    hasData: false,
}

export default ChartBar
