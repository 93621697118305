
import PropTypes from 'prop-types'

const styles = {
    variantGrey: {
        secBackgroundColor: '#f4f4f4',
        secColor: '#111',
        backgroundColor: '#E9EBEE',
        color: '#111',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantTransparent: {
        secBackgroundColor: 'rgba(17, 17, 24, 0.8)',
        secColor: '111111',
        backgroundColor: 'rgba(17, 17, 24, 0.45)',
        color: '#ffffff',
        fontWeight: 500,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        padding: 10,
        backdropFilter: 'blur(10px)',
        height: 50,
    },
    variantBlack: {
        secBackgroundColor: '#E9EBEE',
        secColor: '#111111',
        backgroundColor: '#111111',
        color: '#ffffff',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantSoft: {
        secBackgroundColor: '#F5F9FF',
        secColor: '#2772FF',
        backgroundColor: '#DAE7FF',
        // backgroundColor: '#F5F9FF',
        color: '#2772FF',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantBordered: {
        border: '1px solid #EBF0F9',
        secBackgroundColor: '#F5F9FF',
        secColor: '#2772FF',
        backgroundColor: '#FFF',
        // backgroundColor: '#F5F9FF',
        color: '#2772FF',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantGold: {
        secBackground: 'linear-gradient(-90deg, rgba(191,149,63,0.9) 0%, rgba(252,246,186,0.9) 25%, rgba(179,135,40,0.9) 50%, rgba(251,245,183,0.9) 75%, rgba(170,119,28,0.9) 100%)',
        secColor: '#fff',
        background: 'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
        textShadow: 'rgb(0 0 0 / 50%) 0px 0px 5px',
        color: '#fff',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantDefault: {
        secBackground: '#2772FF',
        secColor: '#fff',
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        color: '#fff',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantBlue: {
        secBackground: '#D1E1FF',
        secColor: '#2772FF',
        background: '#2772FF',
        color: '#fff',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantNoBG: {
        color: '#2772FF',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantDisabled: {
        secBackground: '#2772FF',
        opacity: 0.2,
        secColor: '#fff',
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        color: '#fff',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
    },
    variantProfileIcon: {
        secBackgroundColor: '#f4f4f4',
        secColor: '#111',
        backgroundColor: '#ffffff',
        color: '#111',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 10,
        border: '1px solid #EBF0F9',
    },
}

const Button = ({ children, variant, onDisclose, style, disabled }) => {
    let el = null
    switch (variant) {
        case 'grey':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantGrey,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantGrey.backgroundColor
                        el.style.color = styles.variantGrey.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantGrey.secBackgroundColor
                        el.style.color = styles.variantGrey.secColor
                    }}
                />
            )
        case 'disabled':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantDisabled,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantDisabled.backgroundColor
                        el.style.color = styles.variantDisabled.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantDisabled.secBackgroundColor
                        el.style.color = styles.variantDisabled.secColor
                    }}
                />
            )
        case 'transparent':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantTransparent,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantTransparent.backgroundColor
                        el.style.color = styles.variantTransparent.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantTransparent.secBackgroundColor
                        el.style.color = styles.variantTransparent.secColor
                    }}
                />
            )
        case 'noBG':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantNoBG,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                />
            )
        case 'black':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantBlack,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantBlack.backgroundColor
                        el.style.color = styles.variantBlack.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantBlack.secBackgroundColor
                        el.style.color = styles.variantBlack.secColor
                    }}
                />
            )
        case 'soft':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantSoft,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantSoft.backgroundColor
                        el.style.color = styles.variantSoft.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantSoft.secBackgroundColor
                        el.style.color = styles.variantSoft.secColor
                    }}
                />
            )
        case 'bordered':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantBordered,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantBordered.backgroundColor
                        el.style.color = styles.variantBordered.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantBordered.secBackgroundColor
                        el.style.color = styles.variantBordered.secColor
                    }}
                />
            )
        case 'gold':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantGold,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.background = styles.variantGold.background
                        el.style.color = styles.variantGold.color
                    }}
                    onTouchStart={() => {
                        el.style.background = styles.variantGold.secBackground
                        el.style.color = styles.variantGold.secColor
                    }}
                />
            )
        case 'default':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantDefault,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.background = styles.variantDefault.background
                        el.style.color = styles.variantDefault.color
                    }}
                    onTouchStart={() => {
                        el.style.background = styles.variantDefault.secBackground
                        el.style.color = styles.variantDefault.secColor
                    }}
                />
            )
        case 'profile-icon':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantProfileIcon,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantProfileIcon.backgroundColor
                        el.style.color = styles.variantProfileIcon.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantProfileIcon.secBackgroundColor
                        el.style.color = styles.variantProfileIcon.secColor
                    }}
                />
            )
        case 'blue':
            return (
                <div
                    ref={e => (el = e)}
                    style={{
                        ...styles.variantBlue,
                        ...style,
                    }}
                    children={children}
                    onClick={() => !disabled && onDisclose()}
                    onTouchEnd={() => {
                        el.style.backgroundColor = styles.variantBlue.backgroundColor
                        el.style.color = styles.variantBlue.color
                    }}
                    onTouchStart={() => {
                        el.style.backgroundColor = styles.variantBlue.secBackgroundColor
                        el.style.color = styles.variantBlue.secColor
                    }}
                />
            )
        default:
            throw new Error('unknown variant')
    }
}

Button.propTypes = {
    children: PropTypes.any.isRequired,
    variant: PropTypes.string,
    onDisclose: PropTypes.func,
    style: PropTypes.object,
    disabled: PropTypes.bool,
}

Button.defaultProps = {
    variant: 'default',
    onDisclose: () => null,
    style: {},
    disabled: false,
}

export default Button
