
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useState } from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text'
import Button from 'components/Button'
import { capitalizeString } from 'lib/strings'

import VendorIcon from './VendorIcon'
import Analytics from '../AnalyticsCmp'

const styles = {
    wrapper: {
        padding: '30px 15px',
    },
    btn: {
        padding: '5px',
        borderRadius: 100,
        fontSize: 12,
        width: 100,
    },
}

const InfoContent = ({ link, hits, openEdit }) => {
    const [ copied, setCopied ] = useState(false)

    const onCopy = () => {
        clearTimeout(onCopy.timer)
        setCopied(true)
        onCopy.timer = setTimeout(() => setCopied(false), 1000)
    }

    if (!link) return null

    const shareLink = `${window.location.origin}/s/${link.id}`

    return (
        <div style={styles.wrapper}>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <VendorIcon vendor={link.vendor} />
                    <div style={{ width: 10 }}/>
                    <Text
                        children={link.title || capitalizeString(link.vendor)}
                        size={'h6'}
                        weight={'semiBold'}
                        truncated
                        wrapperStyle={{ width: '90%' }}
                    />
                </div>
                <Text
                    children={link.url}
                    size={'p'}
                    color={'text'}
                    truncated
                    wrapperStyle={{ marginTop: 10 }}
                />
                <div style={{ height: 15 }}/>
                <CopyToClipboard text={shareLink}>
                    <div>
                        <Text
                            children={(
                                <>
                                    <span>{`${window.location.host}/s/`}<b>{link.id}</b></span>
                                </>
                            )}
                            size={'p'}
                            color={'primary'}
                            truncated
                            onClick={onCopy}
                        />
                    </div>
                </CopyToClipboard>
            </div>
            <div style={{ height: 15 }}/>
            <div style={{ display: 'flex' }}>
                <CopyToClipboard text={shareLink}>
                    <div>
                        <Button
                            variant={copied ? 'soft' : 'blue'}
                            children={copied ? 'Copied' : 'Copy'}
                            style={{
                                ...styles.btn,
                                ...(copied
                                    ? {
                                        color: '#fff',
                                        backgroundColor: '#20D193',
                                    }
                                    : {}),
                            }}
                            onDisclose={onCopy}
                        />
                    </div>
                </CopyToClipboard>
                <div style={{ width: 15 }}/>
                <Button
                    variant={'soft'}
                    children={'Edit'}
                    style={{ ...styles.btn }}
                    onDisclose={openEdit}
                />
            </div>
            <div style={{ height: 50 }}/>
            <Analytics hits={hits}/>
        </div>
    )
}

InfoContent.propTypes = {
    link: PropTypes.object,
    hits: PropTypes.object,
    openEdit: PropTypes.func,
}

InfoContent.defaultProps = {
    link: null,
    hits: null,
    openEdit: () => null,
}

export default InfoContent
