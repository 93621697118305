
import { connect } from 'react-redux'
import HomeUI from './components/HomeUI'

const mapState = ({ auth, links }) => ({
    isValid: auth.isValid,
    hasLoaded: links.hasLoaded,
})

export default connect(mapState, null)(HomeUI)
