
export default {
    getTutorials: `
        query getTutorials {
            session {
                creatorsApi {
                    getTutorials {
                        name
                        isActive
                    }
                }
            }
        }
    `,
    disable: `
        mutation deactivate ($name: String!) {
            session {
                creatorsApi {
                    deactivateTutorial (name: $name) {
                        success
                        message
                        errorMessage
                        data
                    }
                }
            }
        }
    `,
}
