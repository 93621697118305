
import PropTypes from 'prop-types'
import { isIOS } from 'react-device-detect'
import { useRef } from 'react'

import { TextInput } from 'components/Input'
import VendorIcon from './VendorIcon'

const styles = {
    input: {
        boxShadow: '0px 2px 5px rgba(142, 123, 87, 0.16)',
        borderRadius: 40,
        fontSize: 16,
        height: 55,
    },
    logo: {
        width: 20,
        height: 20,
        borderRadius: '100%',
    },
}

const CreateLinkInput = ({ value, onChange, vendor }) => {
    const inputRef = useRef(null)
    return (
        <div style={isIOS ? styles.input : {}}>
            <TextInput
                ref={inputRef}
                value={value}
                onChange={onChange}
                placeholder={'Enter social media url...'}
                style={styles.input}
                onFocus={() => {
                    try {
                        inputRef.current.select()
                    } catch (err) {}
                }}
                {...(vendor
                    ? { leftIcon: <VendorIcon vendor={vendor}/> }
                    : { leftIcon: <img src={'https://generalcdn.mysocial.io/smartlink.png'} style={styles.logo} /> })}
            />
        </div>
    )
}

CreateLinkInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    vendor: PropTypes.string,
}

CreateLinkInput.defaultProps = {
    value: '',
    onChange: () => null,
    vendor: 'youtube',
}

export default CreateLinkInput
